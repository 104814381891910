import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/text.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Text"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/typography-text--docs"
      />
      <CodeSnippet
        scope={{ React: React, Text: Text }}
        code={snippet}
        platform="react"
        gitHubLink="type/text"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The text to display.</Text>
          </PropListItem>

          <ClassnamePartial componentName="text" />

          <PropListItem name="color" types={['string']}>
            <Text>Determines the color of the text.</Text>
            <List type="unordered">
              <li>
                <code>contrast</code>
              </li>
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>nonessential</code>
              </li>
            </List>
          </PropListItem>

          <EnvironmentPartial />

          <PropListItem name="hideOverflow" types={['boolean']}>
            <Text>
              Determines whether to truncate the text with an ellipsis.
            </Text>
          </PropListItem>

          <PropListItem name="isTight" types={['boolean']}>
            <Text>
              Determines whether to show the text with tighter line-height.
            </Text>
          </PropListItem>

          <PropListItem name="level" types={['string']}>
            <Text>The level (size) of the text.</Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>micro</code>
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscallaneous">
          <QaIdPartial componentName="text" />
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
