import React from 'react';
import WebTab from './_code-web';
import ReactNativeTab from './_code-reactnative';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="code" title="Text" designCode subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Text" />

      <PlatformTabs
        platforms={{
          web: <WebTab />,
          reactnative: <ReactNativeTab />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
